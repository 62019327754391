'use strict';

import Swiper from 'swiper';
import goTo from 'go-to';

{
    let menuItems = document.querySelectorAll('.go-to');
    menuItems.forEach(item => {

        let toNodeSelector = item.dataset.goTo;
        let toNode = document.querySelector(toNodeSelector);;

        item.addEventListener('click', event => {

            event.preventDefault();

            goTo(toNode);

        });

    });
}

{
    let anchor = document.querySelector('#company-anchor')
    document.querySelector('#about-company-button')
        .addEventListener('click', even => { goTo(anchor); });
}


{

    function initMap() {
        var map = L.sm.map('map',{
            center:[ 64.417499, 40.806000 ],
            zoom:15
        });
        var marker = L.sm.marker([ 64.417499, 40.806000 ]);
        marker.addTo(map);
        var popupContent = '<div class="my-popup-title"><h3>ООО ПКФ “ТЭЧ-Сервис”</h3></div>'+
            '<div class="my-popup-description">ул. 50-летия Октября, 41 а, Новодвинск, Архангельская обл., 164902 </div>';
        marker.addTo(map);
        marker.bindPopup(popupContent);
    }
    window.initMap = initMap;
}


document.querySelectorAll('.swiper-container').forEach(slider => {

    new Swiper(slider, {

        slidesPerView: 4,
        spaceBetween: 30,
        loop: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: slider.querySelector('.swiper-pagination'),
            clickable: true,
            bulletActiveClass: 'slider-pagination__page_active',
            renderBullet: (index, className) => {

                return `
                    <div class="slider-pagination__page ${className}">
                        ${index}
                    </div>
                `;

            },
        },

    });

});

